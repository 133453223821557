
document.addEventListener('turbo:load', function () {
  var openMenu = document.querySelector('.menu-open');
  var menu = document.querySelector('.menu');
  var darken = document.querySelector('.darken');
  var body = document.querySelector('body');

  function toggleMenu() {
    menu.classList.toggle('active');
    openMenu.classList.toggle('active');
    if (menu.classList.contains('active')) {
      darken.style.display = 'block';
      body.style.overflow = 'hidden';
    } else {
      darken.style.display = 'none';
      body.style.overflow = '';
    }
  }
  openMenu.addEventListener('click', function (event) {
    toggleMenu();
    event.stopPropagation();
  });

  menu.addEventListener('click', function (event) {
    event.stopPropagation();
  });

  document.addEventListener('click', function (event) {
    var isClickInsideMenu = menu.contains(event.target);

    if (!isClickInsideMenu && menu.classList.contains('active')) {
      toggleMenu();
    }
  });
});

document.addEventListener('turbo:load', function () {
  const menuItems = document.querySelectorAll('.menu-item.parent');

  menuItems.forEach(function (item) {
    if (item.classList.contains('mobile-show')) {
      item.classList.add('active');
    }

    item.addEventListener('click', function () {
      item.classList.toggle('active');
    });
  });
});


